import React from 'react'
import './Performance.css';

const Performance = () => {
  return (
    <div className='main-block'>
        <div className=''>
            <p className='perf-heading'>Performances</p>
        </div>
        <div className='perf-row'>
            <div>
                <p>Awwsome Team specialize in optimizing website speed through advanced design and technical strategies. Our approach includes responsive design, code minimization, and leveraging CDNs for lightning-fast loading times. Partner with us to transform your digital presence for heightened performance and increased user engagement.</p>
            </div>
            <div>
                <div className='perf-item'>
                    <div className='perf-item-row'>
                        <p className='item-name'>Development</p>
                        <p>95%</p>
                    </div>
                    <div className='perf-line-thin'></div>
                    <div className='perf-line-thick-1'></div>
                </div>
                <div className='perf-item'>
                    <div className='perf-item-row'>
                        <p className='item-name'>UI/UX</p>
                        <p>80%</p>
                    </div>
                    <div className='perf-line-thin'></div>
                    <div className='perf-line-thick-2'></div>
                </div>
                <div className='perf-item'>
                    <div className='perf-item-row'>
                        <p className='item-name'>SEO</p>
                        <p>90%</p>
                    </div>
                    <div className='perf-line-thin'></div>
                    <div className='perf-line-thick-3'></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Performance