import React from 'react'
import './AboutUs.css';
import Header from './Header';
import Footer from './Footer';
import AwwsomeLogo from './Assets/AwwsomeLong.png';
import Copyright from './Copyright';

const AboutUs = () => {
  return (
    <div>
    <Header></Header>
    <div className='about-us-container'>
        <div class="about-us-title"><p>About</p>
        <img src={AwwsomeLogo} alt='img'></img>
        </div>
        <p className='about-us-desc'>   
Welcome to Awwsome, where Awwsome Teamtransform digital experiences through a fusion of innovation and expertise. At the heart of our agency is a dedicated team committed to revolutionizing your online presence. Awwsome Teamspecialize in Speed Optimization, ensuring that your website not only looks stunning but loads with lightning speed, captivating your audience from the first click. Our Design Revamp services breathe new life into your digital identity, creating visually stunning and user-centric interfaces that leave a lasting impression. With SEO Optimization, Awwsome Teamnavigate the ever-evolving landscape of search algorithms, strategically positioning your brand for maximum visibility and organic growth. Additionally, our Content Suggestion service goes beyond the surface, offering tailored recommendations to elevate your online narrative. At Awwsome, Awwsome Teamdon't just build websites; Awwsome Teamcraft immersive digital experiences that propel your brand to new heights. Join us on this transformative journey, where excellence meets innovation, and your success is our paramount goal.
        </p>
    </div>
    <Footer></Footer>
    <Copyright/>
    </div>
  )
}

export default AboutUs;