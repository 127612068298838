import React from 'react'
import './company-slider.css';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Amicus from "./Assets/amicus_logo.webp"
import Anatol from "./Assets/anatol_logo.webp"
import Ebert from "./Assets/ebert_honey.webp"
import George from "./Assets/georgetowncupcake.webp"
import Jetski from "./Assets/jetski_logo.webp"
import Scooter from "./Assets/scooterworks.webp"
import Vfis from "./Assets/VFIS.webp"
import Winemakers from "./Assets/winemakers.webp"


const CompanySlider = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1350,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 4,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
          ,
      };
      
  return (
    <div className='comp-main-block'>
        <p>Companies Awwsome Team Have Worked With </p>
        <Slider {...settings} >
              <div className='comp-item'>
              <div className='comp-item-in'>
                  <img src={Amicus} alt='amicus'/>
              </div>
              </div>
              <div className='comp-item'>
              <div className='comp-item-in'>
                  <img src={Anatol} alt='anatol'/>
              </div>
              </div>
              <div className='comp-item'>
              <div className='comp-item-in'>
                  <img src={Ebert} alt='ebert'/>
              </div>
              </div>
              <div className='comp-item'>
              <div className='comp-item-in'>
                  <img src={George} alt='george'/>
              </div>
              </div>
              <div className='comp-item'>
              <div className='comp-item-in'>
                  <img src={Jetski} alt='jetski'/>
              </div>
              </div>
              <div className='comp-item'>
              <div className='comp-item-in'>
                  <img src={Scooter} alt='scooter'/>
              </div>
              </div>
              <div className='comp-item'>
              <div className='comp-item-in'>
                  <img src={Vfis} alt='vfis'/>
              </div>
              </div>
              <div className='comp-item'>
              <div className='comp-item-in'>
                  <img src={Winemakers} alt='winemakers'/>
              </div>
              </div>
    </Slider>
    </div>
  )
}

export default CompanySlider