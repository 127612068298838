import React from 'react'
import './Banner.css';
import logo from './Assets/Awwsome.png'
import before from './Assets/before.webp'
import after from './Assets/after.webp'
import BeforeAfterSlider from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

const FIRST_IMAGE = {
  imageUrl: after,
  alt: 'After'
};
const SECOND_IMAGE = {
  imageUrl: before,
  alt: 'Before'
};

const Banner = () => {
  return (
    <div className='Banner-box'>
    {/* <div className='Inner-left'><img loading={'lazy'} height={'700px'} width={'900px'} src={after} alt={logo}/></div> */}
    <div className='Inner-left'><BeforeAfterSlider currentPercentPosition={40} firstImage={FIRST_IMAGE} secondImage={SECOND_IMAGE} ></BeforeAfterSlider></div>
    <div className='Inner-right'><h2 className='big-heading'>Transforming Websites:</h2><p>From Blazing Speeds to Dazzling Designs. 
Unveiling the art of seamless browsing experiences, our expert touch merges rapid loading speeds with visually stunning and user-centric designs at Awwsome price.</p></div>
    </div>
  )
}

export default Banner