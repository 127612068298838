import React from 'react'
import './PackageDetails.css'

const PackageDetails = () => {
  return (
    <div className='Pack-info'>
    <div className='Pack-title'><p className='perf-heading'>Packages</p><div className='pack-break'></div></div>
    <div className='Card-Parent'>
        <div className='Card-1'>
        <div className='card-title'><h3>Basic</h3></div>
        <div className='card-price'><p>Price 3500$</p></div>  
            <div className='card-detail-list'>
            <div className='list-in-d'>
                <ul>
            <li>Site Audit</li>
            <li>Branding</li>
            <li>Image Optimization</li>
            <li>Responsive Test</li>
            <li>Support until three months of deployment</li>  
            </ul>
            </div>
        </div>
        <a href='/packages#basic'><button className="Submit-btn">Know More</button></a>
        </div>
        <div className='Card-2'>
            <div className='card-title'><h3>Standard</h3></div>
            <div className='card-price'><p>Price 7000$</p></div>
            <div className='card-detail-list'><div className='list-in-d'>
            <ul>
            <li>Site Audit</li>
            <li>Branding</li>
            <li>Speed Optimization</li>
            <li>Responsive Design</li>
            <li>Redesign</li>   
            <li>Content Suggestion</li>
            <li>Support until six months of deployment</li>  
            </ul>
            </div>
        </div>
        <a href='/packages#standard'><button className="Submit-btn">Know More</button></a>
        </div>
        <div className='Card-3'>
        <div className='card-title'><h3>Premium</h3></div>
            <div className='card-price'><p>Price 15000$</p></div>
            <div className='card-detail-list'><div className='list-in-d'>
            <ul>
            <li>Site Audit</li>
            <li>Branding</li>
            <li>Speed Optimization</li>
            <li>Responsive Design</li>
            <li>Redesign</li>
            <li>Content Suggestion</li>
            <li>SEO Optimization</li>
            <li>Exclusive Support until 1 Year of deployment</li>
            </ul>
            </div>
        </div>
        <a href='/packages#premium'><button className="Submit-btn">Know More</button></a>
        </div>
    </div>
    </div>
  )
}

export default PackageDetails