import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Services from './Services';
import Contact from './Contact';
import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Packages from './Packages';
import AboutUs from './AboutUs';
import ErrorPage from './ErrorPage';


const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
  },
  {
    path: "/packages",
    element: <Packages />,
  },
  {
    path: "/contact-us",
    element:<Contact />
  },
  {
    path: "/services",
    element:<Services/>
  },
  {
    path: "/about-us",
    element:<AboutUs />
  },
  {
    path: "/*",
    element:<ErrorPage/>
  }

]);


ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
