import React, { useState } from 'react'
import './Contact.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import Header from './Header'
import Footer from './Footer'
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue } from "firebase/database";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ContactImage from './Assets/contact.svg'
import Copyright from './Copyright'


let regex = new RegExp('[a-z0-9]+@[a-z]+[./][a-z]{2,3}');

const firebaseConfig = {
    // ...
    // The value of `databaseURL` depends on the location of the database
    databaseURL: "https://awwsome-6b409-default-rtdb.firebaseio.com",
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  
  
  // Initialize Realtime Database and get a reference to the service
  const database = getDatabase(app);
  
  function writeUserData(name,email,message) {
    const uid = Date.now().toString()
    const db = getDatabase();
    set(ref(db, 'users-message/' + uid), {
      name: name,  
      email: email,
      message: message,
    });
  }
  
  const db = getDatabase();
  const starCountRef = ref(db, 'users-message/');
  onValue(starCountRef, (snapshot) => {
    const data = snapshot.toJSON();
    console.log(data)
  });



const Contact = () => {
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [message,setMessage] = useState('');

    const checkData = () => {
        if(name == ''){
            NotificationManager.warning('Enter Name')
        } else if(!regex.test(email)){
            NotificationManager.warning('Enter Valid Email')
        } else if(message == ''){
            NotificationManager.warning('Enter Message')
        } else{
            writeUserData(name,email,message);
            setEmail('')
            setName('')
            setMessage('')
            NotificationManager.success('Message sent')
        }
    }
  return (
    <div>
        <NotificationContainer/>
       <Header></Header>
    <div className='main-container'>
        <div className='parent-container'>
        <div className='child-1'><img src={ContactImage} alt='img' className='co-logo'/></div>
        <div className='child-2'>
            <div className='head'>Contact Us</div>
            <img src={ContactImage} alt='img' className='co-logo-t'/>
            <div className='container'>
                <div className='inputs-container'>
                    <div className='name'><span className='name-span'>Full Name</span><br/><input value={name} onChange={(event)=>{setName(event.target.value)}} type='text'  className='input-name'/></div>
                    <div className='name'><span className='name-span'>Email</span><br/><input value={email} onChange={(event)=>{setEmail(event.target.value)}} type='email' className='input-name'/></div>
                    <div className='name'><span className='name-span'>Message</span><br/><input value={message} onChange={(event)=>{setMessage(event.target.value)}} type='text'  className='input-name'/></div>
                    <div className='button'><input onClick={()=>{checkData()}} type='button' value="Contact Us" className='btn'/></div>
                </div>
                <div className='details-container'>
                    <div className='d-up'>
                    <h5>Contact</h5><p>info@awwsome.co</p>
                    <h5>Based In</h5><p>Surat,Gujarat,India<br/>394325</p>
                    </div>
                    <div className='d-bottom'>
                    <p><a href="https://www.facebook.com/people/Awwsome/61554510712144/"><FontAwesomeIcon icon={icon({name:"square-facebook",family: 'classic', style: 'brands'})} className='social-icon'/></a></p>
                    <p><a href="https://www.instagram.com/awwsome.inc/"><FontAwesomeIcon icon={icon({name:"square-instagram",family: 'classic', style: 'brands'})} className='social-icon'/></a></p>
                    <p><a href="https://twitter.com/awwsome_co"><FontAwesomeIcon icon={icon({name:"x-twitter",family: 'classic', style: 'brands'})} className='social-icon'/></a></p>
                    </div>
                </div>  
            </div>
        </div>
        </div>
    </div>
    <div className='mobile-container'>
        <h1>Contact Us</h1>
        <img img src={ContactImage} alt='img' className='mobile-logo'/>
        <div className='mob-sub-container'>
            <span className='input-span-1'>Name</span><input value={name}  onChange={(event)=>{setName(event.target.value)}} type='text' placeholder='Name' className='mob-input'/>
            <span className='input-span-2'>Email</span><input value={email}  onChange={(event)=>{setEmail(event.target.value)}} type='email' placeholder='Email' className='mob-input'/>
            <span className='input-span-3'>Message</span><input value={message}  onChange={(event)=>{setMessage(event.target.value)}} type='text' placeholder='Message' className='mob-input'/>
            <input onClick={()=>{checkData()}} type='button' value='Contact Us' className='mob-btn'/>
            <span>Contact</span>
            <p><a href="mailto:info@awwsome.co">info@awwsome.co</a></p>
            <span>Based In</span>
            <p>Surat,Gujarat,India<br/>394325</p>
            <div className='mob-icon'>
            <p><a href="https://www.facebook.com/people/Awwsome/61554510712144/"><FontAwesomeIcon icon={icon({name:"square-facebook",family: 'classic', style: 'brands'})} className='social-icon'/></a></p>
            <p><a href="https://www.instagram.com/awwsome.inc/"><FontAwesomeIcon icon={icon({name:"square-instagram",family: 'classic', style: 'brands'})} className='social-icon'/></a></p>
            <p><a href="https://twitter.com/awwsome_co"><FontAwesomeIcon icon={icon({name:"x-twitter",family: 'classic', style: 'brands'})} className='social-icon'/></a></p>
            </div>
        </div>
    </div>
    <Footer/>
    <Copyright/>
    </div>
    
  )
}

export default Contact