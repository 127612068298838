import React from "react";
import "./PackageBody.css";
import BasicPackageImage from './Assets/basic_package.svg'
import StandardPackageImage from './Assets/standard_package.svg'
import PremiumPackageImage from './Assets/premium_package.svg'


const PackageBody = () => {
  return (
    <div className="main-col">
      <section id="basic">
      <div className="package-container-left">
        <img src={BasicPackageImage} alt="basicpackimg">
        </img>
        <div className="package-desc">
          <p className="pd-heading">Basic Plan</p>
          <p className="pd-text">
            <strong>Site Test:</strong> A comprehensive evaluation and analysis of your website's functionality, performance, and user experience. This includes checking for bugs, broken links, and overall website behavior to ensure it functions smoothly across different browsers and devices.
            <br />
            <br />
            <strong>Branding:</strong> Crafting and refining your brand identity, including the design of logos, color schemes, typography, and overall visual elements that represent your company's personality and values. This service aims to create a unique and recognizable brand image.
            <br />
            <br />
            <strong>Image Optimization:</strong> Enhancing website images to ensure optimal performance without compromising quality. This involves compressing image files, adjusting formats, and implementing best practices to improve load times while maintaining high visual standards.
            <br />
            <br />
            <strong>Responsive Test:</strong> Testing your website across various devices (desktops, laptops, tablets, and mobile phones) to ensure it displays and functions correctly on each screen size. This service verifies that the site's layout and features are responsive and adaptable, providing an optimal user experience on all devices.
            <br />
            <br />
            <strong>Support until three months of deployment:</strong> Offering assistance, troubleshooting, and technical support for your website post-launch. This support spans up to three months after deployment, addressing any issues, providing maintenance, and ensuring that your website continues to function optimally during the initial critical period after launch.
          </p>
        </div>
      </div>
      </section>
      <section id="standard">
      <div className="package-container-left">
        <div className="package-desc">
          <p className="pd-heading">Standard Plan</p>
          <p className="pd-text">
          <strong>Site Test:</strong> A comprehensive evaluation and analysis of your website's functionality, performance, and user experience. This includes checking for bugs, broken links, and overall website behavior to ensure it functions smoothly across different browsers and devices.
            <br />
            <br />
            <strong>Branding:</strong> Crafting and refining your brand identity, including the design of logos, color schemes, typography, and overall visual elements that represent your company's personality and values. This service aims to create a unique and recognizable brand image.
            <br />
            <br />
            <strong>Speed Optimization:</strong> Improving the loading speed and overall performance of your website. This service involves various techniques such as optimizing code, reducing file sizes, leveraging browser caching, and employing content delivery networks (CDNs) to make your website load faster. The goal is to enhance user experience, reduce bounce rates, and potentially improve search engine rankings.
            <br />
            <br />
            <strong>Responsive Design:</strong> Creating a website that adapts seamlessly to different devices and screen sizes. This design approach ensures that the layout, content, and functionality of the website are optimized for various platforms, providing a consistent and user-friendly experience regardless of the device being used.
            <br />
            <br />
            <strong>Redesign:</strong> Overhauling and updating the visual and/or structural elements of your website. This service involves a comprehensive review of the current design, layout, and functionality to improve aesthetics, user experience, and possibly the site's overall performance. It may include changes in layout, color schemes, navigation, and other visual or functional aspects to enhance the website's effectiveness and appeal.
            <br />
            <br />
            <strong>Content Suggestions:</strong> Providing recommendations and guidance for enhancing the quality, relevance, and engagement of your website's content. This service includes suggestions for creating or refining content, such as articles, blog posts, videos, or other media, to better resonate with your audience, improve SEO, and align with your brand's goals. It may involve keyword suggestions, content structure advice, and ideas for engaging and informative materials.
            <br />
            <br />
            <strong>Support until six months of deployment:</strong> Offering assistance, troubleshooting, and technical support for your website post-launch. This support spans up to six months after deployment, addressing any issues, providing maintenance, and ensuring that your website continues to function optimally during the initial critical period after launch.
          </p>
        </div>
        <img src={StandardPackageImage} alt="standardpackimg">
        </img>
      </div>
      </section>
      <section id="premium">
      <div className="package-container-left">
      <img src={PremiumPackageImage} alt="premiumpackimg">
          </img>
        <div className="package-desc">
            <p className="pd-heading">Premium Plan</p>
            <p className="pd-text">
            <strong>Site Test:</strong> A comprehensive evaluation and analysis of your website's functionality, performance, and user experience. This includes checking for bugs, broken links, and overall website behavior to ensure it functions smoothly across different browsers and devices.
            <br />
            <br />
            <strong>Branding:</strong> Crafting and refining your brand identity, including the design of logos, color schemes, typography, and overall visual elements that represent your company's personality and values. This service aims to create a unique and recognizable brand image.
            <br />
            <br />
            <strong>Speed Optimization:</strong> Improving the loading speed and overall performance of your website. This service involves various techniques such as optimizing code, reducing file sizes, leveraging browser caching, and employing content delivery networks (CDNs) to make your website load faster. The goal is to enhance user experience, reduce bounce rates, and potentially improve search engine rankings.
            <br />
            <br />
            <strong>Responsive Design:</strong> Creating a website that adapts seamlessly to different devices and screen sizes. This design approach ensures that the layout, content, and functionality of the website are optimized for various platforms, providing a consistent and user-friendly experience regardless of the device being used.
            <br />
            <br />
            <strong>Redesign:</strong> Overhauling and updating the visual and/or structural elements of your website. This service involves a comprehensive review of the current design, layout, and functionality to improve aesthetics, user experience, and possibly the site's overall performance. It may include changes in layout, color schemes, navigation, and other visual or functional aspects to enhance the website's effectiveness and appeal.
            <br />
            <br />
            <strong>Content Suggestions:</strong> Providing recommendations and guidance for enhancing the quality, relevance, and engagement of your website's content. This service includes suggestions for creating or refining content, such as articles, blog posts, videos, or other media, to better resonate with your audience, improve SEO, and align with your brand's goals. It may involve keyword suggestions, content structure advice, and ideas for engaging and informative materials.
            <br />
            <br />
            <strong>SEO Optimization:</strong> The goal is to increase organic traffic, improve the website's visibility on search engines like Google, Bing, and others, and ultimately drive relevant traffic to the site. SEO optimization aims to make the website more authoritative, relevant, and accessible to search engine algorithms, resulting in higher rankings and better exposure to potential visitors.
            <br />
            <br />
            <strong>Support until 1 Year of deployment:</strong> Offering assistance, troubleshooting, and technical support for your website post-launch. This support spans up to 1 Year after deployment, addressing any issues, providing maintenance, and ensuring that your website continues to function optimally during the initial critical period after launch.

            </p>
          </div>

        </div>
        </section>
    </div>
    
  );
};

export default PackageBody;
