import React from 'react'
import './ErrorPage.css'
import ErrorImage from './Assets/404.svg'
import HomeIcon from './Assets/home-icon.png'

const ErrorPage = () => {
  return (
    <div className='Error-Page'><img src={ErrorImage} alt='error' className='Error-img'/><button onClick={()=>{window.location="/"}} className='H-btn'><div><p>Home</p></div><div><img src={HomeIcon} alt='icon' className='H-icon'/></div></button></div>
  )
}

export default ErrorPage