import React, { useState } from 'react'
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import logo from './Assets/Awwsome.png'

const Header = () => {
  const [isOpen,setIsOpen] = useState(false);
  return (
    <div className='header-parent'>
        <div className='logo-container'>
        <a href='/'><p className='logo-text'>Awwsome.co</p></a>
        </div>
        <div className='nav-container'>
            <ul className='nav-list'>
            <li>
                <a href='/'>Home</a>
            </li>
            <li>
                <a href='/packages'>Packages</a>
            </li>
            <li>
                <a href="/services">Services</a>
            </li>
            <li>
                <a href="/about-us">About</a>
            </li>
            <li>
                <a href="/contact-us">Contact Us</a>
            </li>
            </ul>
        </div>
        <div className='drawer-container'>
            <div className='drawer-icon-parent' onClick={()=>setIsOpen(!isOpen)}>
                <FontAwesomeIcon icon={icon({name: 'bars'})} className='drawer-icon'/>
            </div>
        </div>
        <div className={isOpen?'drawer':'drawer-closed'}>
            <div className='top-drawer'>
            <div className='drawer-icon-parent' onClick={()=>setIsOpen(!isOpen)}>
                <FontAwesomeIcon icon={icon({name: 'xmark'})} className='drawer-icon'/>
            </div>
            </div>
            <div className='drawer-col'>
                <img className='drawer-logo' src={logo} alt='logo'/>
                <p className='drawer-desc'>From Blazing Speeds to Dazzling Designs. Unveiling the art of seamless browsing experiences, our expert touch merges rapid loading speeds with visually stunning and user-centric designs.</p>
                <ul className='nav-list-drawer'>
                    <li>
                        <a href='/'>Home</a>
                    </li>
                    <li>
                        <a href='/packages'>Packages</a>
                    </li>
                    <li>
                        <a href="/services">Services</a>
                    </li>
                    <li>
                        <a href="/about-us">About</a>
                    </li>
                    <li>
                        <a href="/contact-us">Contact Us</a>
                    </li>
                </ul>
                <div className='contact'>
                <span className='contact-desc'>Contact Us</span>
                <a href='mailto:info@awwsome.co'><p><FontAwesomeIcon icon={icon({name:'envelope'})}></FontAwesomeIcon></p>info@awwsome.co</a>
                </div>
                <div className='contact'>
                <span className='contact-desc'>Or Call Us</span>
                <a href='tel:+918849278804'><p><FontAwesomeIcon icon={icon({name:'phone'})}></FontAwesomeIcon></p>+918849278804</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header