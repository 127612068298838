import React from 'react'
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue } from "firebase/database";
import { useState } from 'react';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';


const Footer = () => {
  const [email,setEmail] = useState('');

  let regex = new RegExp('[a-z0-9]+@[a-z]+[./][a-z]{2,3}');

  const firebaseConfig = {
    // ...
    // The value of `databaseURL` depends on the location of the database
    databaseURL: "https://awwsome-6b409-default-rtdb.firebaseio.com",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);


  // Initialize Realtime Database and get a reference to the service
  const database = getDatabase(app);

  function writeUserData(email) {
    const uid = Date.now().toString()
    const db = getDatabase();
    set(ref(db, 'users/' + uid), {
      email: email,
    });
    NotificationManager.success('You are now subscribed to Awwsome Newsletter')
    setEmail('');
  }


  return (
    <div className='Footer-Outercontainer'>
      <div className='Footer-Innercontainer'>
        <div className='About-container'>
          <h2>Awwsome.co</h2>
          Transforming Websites:
          From Blazing Speeds to Dazzling Designs.</div>
        <div className='Address-container'><p>Contact Us</p>
              <div className='contact'>
                <a href='mailto:info@awwsome.co'><p><FontAwesomeIcon icon={icon({name:'envelope'})}></FontAwesomeIcon></p>info@awwsome.co</a>
                </div>
                <div className='contact'>
                <p className='call-us'>Or Call Us</p>
                <a href='tel:+918849278804'><p><FontAwesomeIcon icon={icon({name:'phone'})}></FontAwesomeIcon></p>+918849278804</a>
         </div>
          </div>
        <div className='Links-container'>
          <p>Links</p>
          <a href='/packages'>Packages</a>
          <a href='/services'>Services</a>
          <a href='/contact-us'>Contact Us</a>
          <a href='/about-us'>About Us</a>
          </div>
        <div className='Contact-container'><p>Follow</p>
        <div className='news-cont'>
        <input value={email} onChange={(event)=>{setEmail(event.target.value)}} type='email' placeholder='Email' />
        <button className="Submit-btn" onClick={()=>{regex.test(email) ? writeUserData(email) :  NotificationManager.error('Enter Valid Email Address'); }}>Submit</button>
        </div>
        <p className='email-desc'>Get latest news on monthly basis!</p></div>
      </div>
      <NotificationContainer/>
    </div>
  )
}

export default Footer