import React from "react";
import './Standards.css';

const Standards = () => {
  return (
    <div className="Standard-main">
      <div className="inn-container">
      <div className="perf-heading">Awwsome Standards</div>
      <div className="break"></div>
      <div className="container-s">
        <div className="left">
          <div className="left-h"><p>By implementing cutting-edge optimization techniques and adhering to the latest best practices, Awwsome team ensure your site not only meets but exceeds industry benchmarks, securing a leading position in both speed and visibility within your niche.</p></div>
          <div className="left-li">
            <ul>
            <li>Speed optimization</li>
            <li>Redesign</li>
            <li>Outstanding support</li>
            <li>Quality performance</li>
            <li>Page load time</li>
            <li>Minification</li>
            <li>Browser caching</li>
            </ul>
            <ul>
            <li>Lazy loading</li>
            <li>Responsive design</li>
            <li>Code optimization</li>
            <li>Mobile optimization</li>
            <li>CSS and JavaScript optimization</li>
            <li>Image optimization</li>
            <li>Reducing HTTP requests</li>
            </ul>
          </div>
        </div>
        <div className="right">
          <div className="right-grid"> 
          <div className="circle-1">
            <h1>85%</h1>
            <p>Speed <br/>optimization</p>
          </div>
          <div className="circle-2">
            <h1>95%</h1>
            <p>Responsive <br/>Design</p>
          </div>
          <div className="circle-3">
            <h1>80%</h1>
            <p>Quality<br/> Performance</p>
          </div>
          <div className="circle-4">
            <h1>99%</h1>
            <p>Image<br/> optimization</p>
          </div>
          </div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Standards;
