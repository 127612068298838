import React from 'react';
import { useRef, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Banner from './Banner';
import Header from './Header';
import Standards from './Standards';
import Performance from './Performance';
import CompanySlider from './company-slider';
import Footer from './Footer';
import PackageDetails from './PackageDetails'
import Copyright from './Copyright';
import Ad from './Ad';

function App() {

  return (
    <div className="App">
      <header>
        <Header/>
        {/* <img src={logo} className="App-logo" alt="logo" />
        <p>
          Awwsome
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      </header>
      <Ad dataAdSlot={"2626571832"}/>
      <Banner/>
      <Ad dataAdSlot={"2626571832"}/>
      <PackageDetails/>
      <Standards/>
      <Ad dataAdSlot={"2626571832"}/>
      <Performance/>
      <CompanySlider/>
      <Ad dataAdSlot={"2626571832"}/>
      <Footer/>
      <Copyright/>
      
    </div>
  );
}

export default App;
