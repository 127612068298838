import React from 'react';
import './App.css';
import Header from './Header';
import Footer from './Footer';
import PackageBody from './PackageBody';
import Copyright from './Copyright';

function Packages() {
  return (
    <div className="App">
      <header>
        <Header/>
      </header>
      <PackageBody>

      </PackageBody>
      <Footer/>
      <Copyright/>
    </div>
  );
}

export default Packages;
