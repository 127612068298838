import React from 'react'
import './Services.css'
import Service from './Assets/Services.svg'
import WebDesign from './Assets/Custom website.svg'
import UI from './Assets/Ui-Ux.svg'
import Dev from './Assets/Cms.svg'
import Seo from './Assets/premium_package.svg'
import Ecom from './Assets/E-commerce solution.svg'
import Hosting from './Assets/Hosting.svg'
import Header from './Header'
import Footer from './Footer'
import Copyright from './Copyright'

const Services = () => {
  return (
    <div>
        <Header/>
    <div className='Services-outer'>
        <div className='Services-inner'>
            <div className='Service-title'><img src={Service} alt='services'/><h1>Our Services</h1><p>Elevate your online presence with our web development agency, delivering superior and secure solutions tailored to your unique needs. Trust us to transform your vision into a robust digital experience</p></div>
    
        <div className='Sevices-grid'>
            <div className='grid-design'><div className='grid-img'><img src={Dev} alt='img'/></div><h5>Website Design and Development</h5><p className='p-tag'>Creating and building websites tailored to clients' needs.</p></div>
            <div className='grid-design'><div className='grid-img'><img src={Ecom} alt='img'/></div><h5>E-commerce Solutions</h5><p>Developing online stores and integrating payment systems.</p></div>
            <div className='grid-design'><div className='grid-img'><img src={WebDesign} alt='img'/></div><h5>Custom Web Application Developmen</h5><p>Building specialized web applications for specific purposes.</p></div>
            <div className='grid-design'><div className='grid-img'><img src={UI} alt='img'/></div><h5>UI/UX Design</h5><p>Designing user interfaces and experiences for websites and applications.</p></div>
            <div className='grid-design'><div className='grid-img'><img src={Seo} alt='img'/></div><h5>SEO (Search Engine Optimization)</h5><p>Optimizing websites to rank higher in search engine results.</p></div>
            <div className='grid-design'><div className='grid-img'><img src={Hosting} alt='img'/></div><h5>Web Hosting and Maintenance</h5><p>Providing hosting services and ongoing maintenance for websites.</p></div>
        </div>
    </div>
    </div>
   <Footer/>
   <Copyright/>
    </div>

  )
}

export default Services